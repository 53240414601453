<template>
  <div class="page" @click="toNext">
    <div class="cloud">
      <img src="@/assets/images/cloud.png" />
    </div>
    <div class="birds">
      <img src="@/assets/images/birds.png" />
    </div>
    <div class="main-content">
      <div class="animation-block-1">
        <div class="text">MIRROR-EDU</div>
      </div>
      <div class="animation-block-2">
        <div class="text">为你</div>
      </div>
      <div class="animation-block-3">
        <div class="text">都在此处</div>
      </div>
      <div class="animation-block-4">
        <div class="text">上海镜台国际教育</div>
      </div>
      <div class="animation-block-5">
        <!-- <img src="@/assets/images/text_zlxnqbb.webp" /> -->
        <div class="text">众里寻你千百度</div>
      </div>
      <div class="animation-block-6">
        <div class="text">愿望</div>
      </div>
      <div class="animation-block-7">
        <div class="text">实现</div>
      </div>
      <div class="animation-block-8">
        <img src="@/assets/images/border_top_right.png" />
      </div>
      <div class="animation-block-9"></div>
    </div>
  </div>
</template>

<script>
export default {
    methods:{
        toNext(){
            this.$router.push('/main')
        }
    }
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
  background-color: #127987;
  font-family: muyao;
  .cloud {
    width: 100%;
    height: 246px;
    position: absolute;
    top: 50%;
    z-index: 1;
    margin-top: -123px;
    animation: 14s linear 0s infinite normal both running
      tempKeyframesForMotion0;
    img {
      height: 100%;
    }
  }
  .birds {
    width: 87px;
    height: 44px;
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 75px;
    margin-top: -44px;
    animation: 8s linear 0s infinite normal both running tempKeyframesForMotion1;
    img {
      height: 100%;
    }
  }
  .main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    width: 320px;
    height: 486px;
    transform: translate(-50%, -50%);
    .animation-block-1 {
      position: absolute;
      z-index: 12;
      width: 273px;
      height: 46px;
      line-height: 46px;
      top: 132.6px;
      left: 20.6px;
      text-align: center;
      animation: 2s ease 0.3s 1 normal both running fadeInUp;
      .text {
        padding: 5px;
        font-family: muyao;
        font-size: 24px;
        color: rgb(252, 243, 203);
      }
    }
    .animation-block-2 {
      position: absolute;
      z-index: 12;
      width: 100px;
      height: 76px;
      line-height: 76px;
      top: 170.6px;
      left: 129.933px;
      text-align: center;

      animation: 2s ease 0.6s 1 normal both running lightSpeedInLeft;
      .text {
        padding: 5px;
        font-family: muyao;
        font-size: 44px;
        color: rgb(252, 243, 203);
      }
    }
    .animation-block-3 {
      position: absolute;
      z-index: 12;
      width: 75px;
      height: 31px;
      line-height: 31px;
      top: 209.8px;
      left: 84.2px;
      text-align: center;

      animation: 2s ease 0.5s 1 normal both running fadeInRight;
      .text {
        padding: 5px;
        font-family: muyao;
        color: rgb(252, 243, 203);
        font-size: 14px;
      }
    }
    .animation-block-4 {
      position: absolute;
      z-index: 12;
      width: 273px;
      height: 31px;
      line-height: 31px;
      top: 294.6px;
      left: 90.6px;
      text-align: left;
      animation: 2s ease 0.8s 1 normal both running fadeInUp;

      .text {
        padding: 5px;
        font-family: muyao;
        color: rgb(252, 243, 203);
        font-size: 14px;
      }
    }
    .animation-block-5 {
      position: absolute;
      z-index: 12;
      width: 140px;
      height: 15px;
      line-height: 15px;
      top: 173.7px;
      left: 84.3px;
      text-align: center;
      animation: 2s ease 0.4s 1 normal both running fadeInLeft;
      .text {
        padding: 5px;
        font-family: muyao;
        color: rgb(252, 243, 203);
        font-size: 18px;
      }
    }
    .animation-block-6 {
      position: absolute;
      z-index: 12;
      width: 74px;
      height: 49px;
      line-height: 49px;
      top: 179.467px;
      left: 81.8667px;
      text-align: left;
      animation: 2s ease 0.8s 1 normal both running fadeInUp;

      .text {
        padding: 5px;
        font-family: muyao;
        color: rgb(252, 243, 203);
        font-size: 26px;
      }
    }
    .animation-block-7 {
      position: absolute;
      z-index: 13;
      width: 166px;
      height: 100px;
      line-height: 100px;
      top: 220.8px;
      left: 90.2px;
      text-align: left;
      animation: 2s ease 0.8s 1 normal both running fadeInUp;
      .text {
        padding: 5px;
        font-family: muyao;
        font-size: 60px;
        color: rgb(7, 121, 137);
      }
    }
    .animation-block-8 {
      position: absolute;
      z-index: 12;
      width: 117px;
      height: 62.5px;
      top: 237.8px;
      left: 104.3px;
      text-align: left;
      animation: 2s ease 0.6s 1 normal both running rollInLeft;
      img {
        height: 100%;
      }
    }
    .animation-block-9 {
      position: absolute;
      z-index: 12;
      width: 118px;
      height: 62.5px;
      top: 242.3px;
      left: 97.8px;
      text-align: left;
      animation: 2s ease 0.6s 1 normal both running rollInRight;
      background-color: #f4c251;
    }
  }
}
</style>